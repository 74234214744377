import { triggerCustomEvent } from '@helpers/miscelleanous';
import AppDownloadNudge from './AppDownloadNudge';
import { Image, Text } from '@ui/atoms';
import React, { useEffect } from 'react'

const NavItems = ({
    navItems,
    viewMoreLinks,
    setViewMoreLinks,
    redirectTo,
    listedUrlsExceptMoreLinks,
    router,
    setIsBottomNavVisible,
    highlights,
    setHighlights
}) => {

  useEffect(() => { 
    setIsBottomNavVisible(true) 

    return () => {
         setIsBottomNavVisible(false)
    }
  }, [])

  const handleClick = (item) => {
    setHighlights(item?.id)
    if(item?.gTag) triggerCustomEvent(item?.gTag);
    if(item?.redirect){
        redirectTo(item.redirect)
    }else{
        setViewMoreLinks(viewMoreLinks.length === 0 ? item.moreLinks : [])
    }
  }

  const getImageSrc = (item, highlights, listedUrlsExceptMoreLinks, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const isMoreLink = !listedUrlsExceptMoreLinks.includes(routerPathname) && item?.id === 'more';
  
    return (highlights ? highlights === item?.id : (isRedirectMatch || isMoreLink))
      ? item.activeImage
      : item.image;
  };

  const getTextClassNames = (item, highlights, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const textColor = (highlights ? highlights === item?.id : isRedirectMatch) ? 'text-primary-500' : 'text-gray-500';
  
    return `p7-medium text-center ${textColor}`;
  };
  

  return (
    <div className='fixed bottom-0 w-full xl:hidden flex flex-col items-center' style={{
      zIndex: 98
    }}>
      <div className="flex w-full justify-start">
        <AppDownloadNudge />
      </div>
      <div className={`bg-basicWhite w-full grid-cols-${navItems.length} grid gap-1 px-3 shadow-bottom-sheet`}>
        {navItems.map((item, index) => (
          <div
            key={index}
            className="flex justify-center items-center flex-col gap-2 bg-basicWhite/80 relative z-10 py-3 cursor-pointer"
            onClick={() => handleClick(item)}
          >
            <div className="relative">
              <Image
                src={getImageSrc(
                    item,
                    highlights,
                    listedUrlsExceptMoreLinks,
                    router.pathname
                )}
                width={24}
                height={24}
              />
              {/* {item?.newFeatureHighlight && (
                <div className="absolute flex items-center justify-center w-2 h-2 rounded-full -top-0 -right-0 bg-tax-insights-solidPink" />
              )} */}
              {item?.highlight && (
                <div className="absolute flex items-center justify-center w-3 h-3 rounded-full -bottom-0 -left-0 bg-basicWhite">
                  <div className="w-2 h-2 rounded-full bg-semantic-success-base" />
                </div>
              )}
            </div>
            <Text
              content={item.label}
              className={getTextClassNames(item, highlights, router.pathname)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default NavItems;